import Template from '../components/Template'
import * as style from './style.module.scss'
import React from 'react'
import { Helmet } from 'react-helmet'

const PageTerms = () => (
  <Template>
    <div className={style.pageWrapper}>
      <Helmet>
        <title>Terms of service</title>
      </Helmet>

      <div className={style.titleContainer}>
        <h1>Terms of service</h1>
        <p>
          <strong>Last updated:</strong> Feb 16, 2025
        </p>
      </div>
      <p>Welcome to doggos.com!</p>
      <p className={style.termsNote}>
        (a.k.a. <strong>"we"</strong> or <strong>"us"</strong> or{' '}
        <strong>"the website"</strong> or <strong>"the company"</strong> )
      </p>
      <p>It's great to have you as a user and member of the community.</p>
      <p>
        The following terms and conditions (collectively, the "terms of
        service") apply to your use of doggos.com ("the website").
      </p>
      <dl>
        <h4 className={style.calloutTitle}>
          <span className={style.dogEmoji} role='img' aria-label='dog'>
            🐶
          </span>
          Acceptance of the terms of service
        </h4>
        <p>
          By using doggos.com you accept and agree to abide by these terms of
          service.
        </p>
        <p>
          We reserve the right to update the website and these terms of service
          at any time, at our sole discretion. Your continued use following
          updates to the terms of service means that you accept the changes.
        </p>
      </dl>

      <h3>
        Accessing the website, <br />
        security and privacy
      </h3>
      <p>We make no guarantees about accessibility or uptime.</p>
      <p>
        We also reserve the right to suspend or restrict access to some features
        or users. Regardless, we will not be liable if all or any part of the
        website is unavailable at any time, or for any duration.
      </p>
      <p>
        To access certain features, you have to register by entering your email
        and choosing a password as part of our security procedure. You must
        treat such information as confidential, not disclosing it to any third
        party, and only logging into doggos.com in person.
      </p>
      <p>
        It is a condition of your use of the website that all the information
        you provide is true and correct. We reserve the right to disable any
        user account, at any time in our sole discretion for any or no reason,
        including, if in our opinion you have failed to comply with any
        provision of these terms of service.
      </p>
      <h3>
        Intellectual property <br />
        rights and use guidelines
      </h3>
      <dl>
        <p>
          <strong>
            Doggos®™ is a registered trademark with the United States Patent and
            Trademark Office.
          </strong>
        </p>
        <p>
          The use of our trademarks, logos, or service marks without our prior
          written consent is strictly prohibited. All other trademarks, logos,
          or service marks used on the doggos.com website are the property of
          their respective owners.
        </p>
      </dl>

      <p>
        The website and its original content, features and functionality
        (including design!), are owned by doggos.com, Inc., and are protected by
        United States copyright, trade secret and other intellectual property &
        proprietary laws. You agree to not copy, modify, create derivative works
        of or republish, any of our copyrighted material.
      </p>
      <p>
        For purposes of these terms of service, the term “content” includes,
        without limitation information, data, text, photos, videos, audio,
        written posts and all software that is generated, provided, or otherwise
        made accessible on or through doggos.com.
      </p>
      <p>
        If you have questions about how to use our materials, please{' '}
        <a href='mailto:legal@doggos.com' title='Email legal@doggos.com'>
          contact us.
        </a>
      </p>
      <h3>User content </h3>
      <p>
        All content that is added, created, uploaded, submitted, distributed, or
        posted to the website by its users (collectively “user content”),
        whether publicly or privately transmitted, is the sole responsibility of
        the person who originated such user content.
      </p>
      <p>
        You represent that all user content provided by you is in compliance
        with all applicable laws, rules and regulations.
      </p>
      <p>
        We do not undertake to review all material before it is posted on this
        website, and cannot ensure prompt removal of objectionable material
        after it has been posted. Accordingly, we assume no liability for any
        action or inaction regarding transmissions, communications or content
        provided by any user of this website.
      </p>
      <h3>
        Disclaimer of warranties, <br />
        limitations of liability and indemnification.
      </h3>
      <p>
        Doggos.com is provided "as is" and "as available". We disclaim all
        warranties of any kind, express or implied, including, without
        limitation, the warranties of merchantability, fitness for a particular
        purpose and non-infringement.
      </p>
      <p>
        We are not liable for damages, direct or consequential, resulting from
        your use of this website and you agree to hold us harmless from any
        claims, losses, liability costs and expenses (including attorney's fees)
        arising from your violation of any third-party's rights.
      </p>
      <p>
        Because websites are not error or bug free, you agree that you will
        avoid using this website in ways which might result in any loss of your
        or any third party's property or information.
      </p>
      <h3 id='mutual_arbitration'>Mutual arbitration agreement</h3>
      <p>
        This section explains how disputes between users and doggos.com are
        handled. Please make sure you agree to these terms before using this
        website.
      </p>
      <p>
        <strong className={style.highlight}>
          You and doggos.com understand and agree that any existing or future
          dispute or claim arising out of or related to your use of the website
          will be resolved by arbitration and that no other dispute resolution
          will be available, except where excluded by law.
        </strong>{' '}
        The decision of the arbitrator shall be final and binding on both you
        and doggos.com and it shall be enforceable by any court having proper
        jurisdiction.
      </p>
      <p></p>
      <p>
        Each party will be solely responsible for all fees it incurs in
        arbitration, including without limitation, attorney and arbitration
        fees.
      </p>
      <div className={style.arbitration}>
        {' '}
        <a
          className={style.note}
          href='https://www.americanbar.org/groups/dispute_resolution/resources/disputeresolutionprocesses/arbitration/'
          target='_blank'
          title='Visit americanbar.org'
        >
          Learn more about arbitration
        </a>
      </div>
      <h3>Bookings & payments</h3>
      <p>
        This website facilitates payments between dog owners and pet
        professionals ("hosts"/"sitters"), but all liability remains fully with
        the original care provider. You agree to release Doggos.com, Inc. from
        all liability resulting from transactions between yourself and other
        users of the platform.
      </p>
      <h5 id='adjustments'>For pet care providers ("hosts"/"sitters")</h5>
      <p>
        If you use doggos.com to accept payments, you agree that you are at
        least 18 years of age, are representing yourself and your services
        truthfully, and that you are not attempting to circumvent the controls
        we have put into the software.{' '}
        <strong>
          You also agree that debits/credits to your account may be adjusted by
          doggos.com at any time and at our sole discretion,{' '}
        </strong>
        although we do provide you with notifications of these adjustments, and
        ways to appeal them.
      </p>
      <dl>
        <p>
          <strong>Note:</strong> If you would like to request a refund but no
          longer see the option available in your account, get in touch with the
          doggos.com support team by messaging "@doggos" in your chat to discuss
          options.
        </p>
      </dl>
      <h5>Refunds</h5>
      <p>
        Doggos.com has 1 refund policy that applies to everyone. Dog owners can
        receive a full refund if they cancel within 72 hours of their bookings'
        "drop-off date." This amount will be deducted from the "Pending funds"
        in the dog sitter's account, and returned to the original card.
      </p>

      <h3>Waiver and severability</h3>
      <p>
        Our failure to exercise or enforce any provision of the terms of service
        shall not constitute a waiver of such right or provision. The terms of
        service constitutes the agreement between you and doggos.com and govern
        your use of the services, superseding any prior agreements (including,
        but not limited to, any prior versions of the terms of service).
      </p>
      <dl>
        <p>
          Any legal suit, action or proceeding arising out of, or related to,
          these terms of service or the website shall be instituted exclusively
          in the federal courts of the United States or the courts of the State
          of Delaware.
        </p>
      </dl>
      <p>
        If any provision of these terms of service is held by a court of
        competent jurisdiction to be invalid, illegal or unenforceable for any
        reason, such provision shall be updated to the minimum extent that the
        remaining provisions of the terms of service still apply.
      </p>
    </div>
  </Template>
)

export default PageTerms
